import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './LanguageSwitcher.scss';

import rsFlag from './assets/images/rs-flag.jpg';
import enFlag from './assets/images/en-flag.jpg';

function LanguageSwitcher() {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const getTranslatedPath = (lang) => {
        const currentPath = window.location.pathname.substring(3);
        const currentRoutes = t('routes', { lng: i18n.language, returnObjects: true });
        const targetRoutes = t('routes', { lng: lang, returnObjects: true });
        console.log(currentRoutes)
        console.log(targetRoutes)
        const routeKey = Object.keys(currentRoutes).find(key => currentRoutes[key] === currentPath);
        return routeKey ?  `/${lang}${targetRoutes[routeKey]}` : `/${lang}/`;
    };

    const changeLanguage = (lang) => {
        const targetPath = getTranslatedPath(lang);
        console.log(targetPath)

        i18n.changeLanguage(lang);
        navigate(targetPath); // Navigate to the corresponding path in the target language
    };

    return (
        <div className="language-switcher">
            <div className="language-option" onClick={() => changeLanguage('rs')}>
                <img src={rsFlag} alt="Serbian Flag" className="flag-icon" />
            </div>
            <div className="language-option" onClick={() => changeLanguage('en')}>
                <img src={enFlag} alt="English Flag" className="flag-icon" />
            </div>
        </div>
    );
}

export default LanguageSwitcher;
