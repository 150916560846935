import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaBars, FaTimes } from 'react-icons/fa';

import "./AppHeader.scss";
import logo from "../../assets/images/logo/logo.png";
import json from '../../assets/json/header.json';
import LanguageSwitcher from './LanguageSwitcher'; // Import the LanguageSwitcher component

export default function AppHeader() {
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(); // Use translation
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = (e) => {
        e.preventDefault();
        const classListString = Array.from(e.target.classList).join(' ');
        if (classListString.includes('active')) {
            setMenuOpen(false);
        }
        const href = e.currentTarget.getAttribute('href');
        if (classListString.includes('a-item')) {
            window.open(href, '_blank');
        } else {
            navigate(href);
        }
    }

    return (
        <header className="app-header">
            <Link className="logo flex-center" to={{ pathname: `/${i18n.language}`, state: { from: location } }}>
                <img src={logo} alt="Visual Impact Group Logo" />
            </Link>
            <div className="menu-icon">
                {menuOpen ? <FaTimes onClick={toggleMenu} /> : <FaBars onClick={toggleMenu} />}
            </div>
            <nav className={menuOpen ? 'nav-menu active' : 'nav-menu'}>
                {json.headers.sort((a, b) => a.priority - b.priority).map((value, index) => {
                    const url = value.urlKey ? t(value.urlKey) : value.url; // Get URL using translation key or static URL

                    return value.element === "nav" ? (
                        <Link onClick={closeMenu} className={menuOpen ? 'active' : ' '}
                              to={{ pathname: `/${i18n.language}${url}`, state: { from: location } }}
                              key={`nav-item-${value.name}-${index}`}>{t(value.name)}</Link>
                    ) : (
                        <Link onClick={closeMenu} className={menuOpen ? 'a-item- active' : 'a-item'}
                              key={`a-item-${value.name}-${index}`} to={url} target="_blank"
                              rel="referrer">{t(value.name)}</Link>
                    )
                })}
                <LanguageSwitcher /> {/* Add the LanguageSwitcher here */}
            </nav>
        </header>
    );
}
