import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import './assets/style/footer.scss';
import logo from './assets/images/logo.png';

function Footer() {
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const getRoutePath = (key) => `/${lang}${t(`routes.${key}`, { lng: lang })}`;

    return (
        <footer className="footer-main-container row-container footer-bg">
            <div className="row-item flex flex-position-center">
                <img className="logo-img" src={logo} alt="Visual Impact Logo" />
            </div>
            <div className="row-item flex flex-position-center">
                <div className="site-map-container">
                    <h2 className="site-map-header">{t('header.sitemap')}</h2>
                    <div className="site-map-nav-container">
                        <NavLink className="footer__nav-link" to={getRoutePath('aboutUs')}>{t('header.aboutUs')}</NavLink>
                        <NavLink className="footer__nav-link" to={getRoutePath('salesAndConsultations')}>{t('header.salesAndConsultations')}</NavLink>
                        <NavLink className="footer__nav-link" to={getRoutePath('brands')}>{t('header.brands')}</NavLink>
                        <a className="footer__nav-link" target="_blank" rel="noopener noreferrer" href='https://ob.visuals.rs/'>{t('header.obTruck')}</a>
                        <NavLink className="footer__nav-link" to={getRoutePath('contact')}>{t('header.contact')}</NavLink>
                    </div>
                </div>
            </div>
            <div className="row-item flex flex-position-center">
                <p className="footer-address" about="Visual Impact Group Belgrade address">
                    Oblakovska 28, 11000 Belgrade
                </p>
            </div>
        </footer>
    );
}

export default Footer;
