import { lazy } from 'react';
import i18n from 'i18next'; // Import the i18n instance

const routesConfig = [
    {
        pathKey: 'routes.home',
        component: lazy(() => import('../pages/home/HomePage')),
        exact: true
    },
    {
        pathKey: 'routes.salesAndConsultations',
        component: lazy(() => import('../pages/consultations/SalesAndConsultationsPage')),
        exact: true
    },
    {
        pathKey: 'routes.aboutUs',
        component: lazy(() => import('../pages/about-us/AboutUsPage.js')),
        exact: true
    },
    {
        pathKey: 'routes.brands',
        component: lazy(() => import('../pages/brands/BrandsPage.js')),
        exact: true
    },
    {
        pathKey: 'routes.contact',
        component: lazy(() => import('../pages/contact/ContactPage.js')),
        exact: true
    }
];

export const generateRoutes = () => {
    const lang = i18n.language; // Get the current language

    return routesConfig.map(route => ({
        path: `/${lang}${i18n.t(route.pathKey)}`, // Use i18n.t to translate the path
        component: route.component,
        exact: route.exact,
    }));
};

export default generateRoutes;
