import React from "react";
import "./Loading-spinner.scss";
import logo from "../../assets/images/logo/logo-no_text.png"

export default function LoadingSpinner() {
    return (
        <div className="spinner-container">
            <div className="loading-spinner">
                <img alt = "Visuals RS logo" src={logo} className="load-spinner-img" width="80px"/>
            </div>
        </div>
    );
}
