import i18n from '../i18n';

const detectUserCountry = async () => {
    try {
        const response = await fetch('https://ipapi.co/json/');

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const country = data.country;

        if (country === 'RS') {
            await i18n.changeLanguage('rs');
        } else {
            await i18n.changeLanguage('en');
        }
    } catch (error) {
        console.error('Error detecting country:', error);
        await i18n.changeLanguage('rs');
    }
};

export default detectUserCountry;
