import './App.scss';
import React, { Suspense, useEffect } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation,
    Navigate
} from "react-router-dom";

import NotFoundPage from "../pages/not-found/NotFoundPage";
import LoadingSpinner from "../components/loading-spinner/LoadingSpinner";
import AppHeader from "../components/header/AppHeader";
import Footer from "../components/footer/Footer";

import { AnimatePresence, motion } from "framer-motion";
import ScrollToTop from "react-scroll-to-top";
import i18n from '../i18n';
import generateRoutes from '../routes/routes'; // The function we created earlier
import detectUserCountry from "../utils/detectUserCountry";

const App = () => {
    const location = useLocation();
    const routes = generateRoutes(); // Generate routes based on current language

    useEffect(() => {
        const pathLang = location.pathname.split('/')[1]; // Get the first part of the path (language code)
        const availableLanguages = ['rs', 'en']; // Define supported languages

        // Check if the URL contains a valid language code
        if (availableLanguages.includes(pathLang)) {
            // If the language in the URL is different from the current language, change it
            if (i18n.language !== pathLang) {
                i18n.changeLanguage(pathLang);
            }
        } else {
            // If no valid language is in the URL, detect the user's language based on their location
            detectUserCountry();
        }
    }, [location.pathname]);

    return (
        <AnimatePresence mode={'wait'}>
            <motion.div
                key={location.pathname}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}>
                <ScrollToTopAction />
                <Routes location={location}>
                    <Route index path="/" element={<Navigate to={`/${i18n.language}/`} />} />
                    {routes.map(({ path, component: Component, exact }) => (
                        <Route
                            key={path}
                            path={path}
                            exact={exact}
                            element={
                                <div>
                                    {path === `/${i18n.language}/` ? null : <AppHeader />}
                                    <Component key={location.pathname} />
                                    <ScrollToTop smooth />
                                </div>
                            }
                        />
                    ))}
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <Footer />
            </motion.div>
        </AnimatePresence>
    );
};

function ScrollToTopAction() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function AppWrapper() {
    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingSpinner />}>
                <App />
            </Suspense>
        </BrowserRouter>
    );
}

export default AppWrapper;
