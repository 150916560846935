import React from "react";

function NotFoundPage() {
    return (
        <div className="not-found-container">
            <h1>404 Not found</h1>
        </div>
    );
}

export default NotFoundPage;
